import ReactGtmModule from 'react-gtm-module';
import { getDataLayerFromPage } from 'src/utils/getDataLayerFromPage';
import { metricsLogger } from 'src/utils/metricsLogger';
import { wait } from 'src/utils/wait';

type DataLayer = {
  [key: string]: string;
};

const sendDataToDataLayer = async (data: any): Promise<void> => {
  let counter = 6;
  let thisDataLayer: DataLayer[] | undefined = undefined;
  let dataLayerExist = false;
  do {
    counter--;
    await wait(200);
    thisDataLayer = getDataLayerFromPage();
    if (thisDataLayer && thisDataLayer.length > 0) dataLayerExist = true;
  } while (counter > 0 && !dataLayerExist);
  //
  if (!thisDataLayer || thisDataLayer.length < 1) return;

  metricsLogger(data, 'sendDataToDataLayer');

  ReactGtmModule.dataLayer(data);
};

interface GtmDataLayer {
  sendDataToDataLayer: (data: any) => void;
}

export const useGtmDataLayer = (): GtmDataLayer => {
  return {
    sendDataToDataLayer,
  };
};
