type DataLayer = {
  [key: string]: string;
};

interface windowWithDataLayer {
  dataLayer?: DataLayer[];
}

export const getDataLayerFromPage = () => {
  if (!process?.browser) return undefined;
  const windowDataLayer = window as windowWithDataLayer;
  if (!windowDataLayer?.dataLayer) return undefined;
  return windowDataLayer.dataLayer;
};
