export const backendHost = process.env.BACKEND_URL || 'https://api.cloud.ru';
export const consoleUrl =
  process.env.NEXT_PUBLIC_CONSOLE_URL || 'https://console.cloud.ru';
export const consoleMarketplaceUrl =
  process.env.NEXT_PUBLIC_MARKETPLACE_CONSOLE_URL ||
  'https://marketplace.cloud.ru';
export const consoleUrlReg = `${consoleUrl}/registration/`;
export const consoleRedirectUrl = `${consoleUrl}/static-page/login-destination`;

export const gtmId = 'GTM-KLNL8NV';
export const ymId = process.env.NEXT_PUBLIC_YM_ID || '75066511';
export const testYmId = '93605518';
export const revalidate = 120; // как часто обращаться к бэку в статичных страницах - в секундах
