declare function mindbox(type: 'async' | 'sync', options: any): void;

export const mindboxPageView = (url: string) => {
  mindbox('async', {
    operation: 'Website.PageView',
    data: {
      customerAction: {
        customFields: {
          websitePageView: `${process.env.CONTENT_BASE_URL + url}`,
        },
      },
    },
  });
};
