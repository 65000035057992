// TODO такой функционал есть в пакете @sbercloud/ft-debug-mode, а такой логгер существует внутри пакета @sbercloud/ft-metric-client, если перейдем на что то из этих пакетов, то нужно будет убрать э
const convertJsonToString = (obj?: Record<string, unknown>) =>
  JSON.stringify(obj, null, 2);

const DEBUG_METRICS_LOCALSTORAGE_KEY = 'debug_metrics_enabled';

const isDebugMetricsEnabled = () => {
  const isKeyInLocalSotage = Object.prototype.hasOwnProperty.call(
    localStorage,
    DEBUG_METRICS_LOCALSTORAGE_KEY,
  );

  if (!isKeyInLocalSotage) return false;

  const isDebugMetricsEnabled = JSON.parse(
    window.localStorage.getItem(DEBUG_METRICS_LOCALSTORAGE_KEY) || '',
  );

  return isDebugMetricsEnabled;
};

// TODO как будто можно вынести в utils, тк еще в одном месте используется
const checkSpace = () => {
  const url = window.location.href;
  const condition =
    url.indexOf('autotest') !== -1 ||
    url.indexOf('shadow') !== -1 ||
    url.indexOf('cloudtest') !== -1;

  if (
    condition ||
    process.env.NEXT_PUBLIC_SPACE === 'develop' ||
    process.env.NEXT_PUBLIC_SPACE === 'stage'
  ) {
    return 'dev';
  }

  return 'prod';
};

export const metricsLogger = (
  data: Record<string, unknown>,
  definition?: string,
) => {
  const eventDefinition = definition ? `event definition: ${definition}` : '';

  if (isDebugMetricsEnabled() && checkSpace() === 'dev') {
    console.log(
      `DEV_ALERT(Metric Client): ${eventDefinition} ${convertJsonToString(
        data,
      )}`,
    );
  }
};
