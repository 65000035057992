import 'src/assets/scss/index.scss';

import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import ReactGtmModule from 'react-gtm-module';
import { gtmId, ymId } from 'src/global';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { StoreProvider } from 'src/store/index';
import { getUtmFromUrl } from 'src/utils/getUtmFromUrl';
import { mindboxPageView } from 'src/utils/mindbox';

declare global {
  interface Window {
    ym: (...args: any) => void;
  }
}

function PreviewApp({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />;
}

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { pageViewCustom } = useAnalytics();

  useEffect(() => {
    if (document && process.env.NEXT_PUBLIC_SPACE === 'production') {
      const tagManagerArgs = {
        gtmId: gtmId,
        dataLayerName: 'dataLayer',
        dataLayer: {
          originalLocation: document.location.href,
        },
      };

      ReactGtmModule.initialize(tagManagerArgs);
    }
  }, []);

  useEffect(() => {
    if (window) {
      if (getUtmFromUrl(window.location.href) === '') return;
      window.localStorage.setItem(
        'originalUTM',
        getUtmFromUrl(window.location.href),
      );
    }
  }, []);

  useEffect(() => {
    const mindboxInit = `
      mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
      mindbox.queue = mindbox.queue || [];
      mindbox('create');
      `;
    const mindboxInitFunction = new Function(mindboxInit);
    process.env.NEXT_PUBLIC_SPACE === 'production' && mindboxInitFunction();
  }, []);

  //событие mindbox "просмотр страницы"
  useEffect(() => {
    process.env.NEXT_PUBLIC_SPACE === 'production' &&
      mindboxPageView(router.pathname);
  }, [router.pathname]);

  // событие ym hit "показ страницы"
  useEffect(() => {
    const handleRouteChange = () => {
      if (window && window.ym) {
        pageViewCustom();
        window.ym(ymId, 'hit', window.location.href, {
          params: {
            title: document.title,
            referer: document.referrer,
          },
        });
      }
    };

    handleRouteChange();
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router.events]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Ищем таланты: https://cloud.ru/career');
  }, []);

  return (
    <StoreProvider>
      <Component {...pageProps} />
    </StoreProvider>
  );
}

export default function App(props: AppProps) {
  const router = useRouter();

  const isPreview = router.pathname.slice(0, 8) === '/preview';

  return isPreview ? <PreviewApp {...props} /> : <CustomApp {...props} />;
}
