import { testYmId, ymId } from 'src/global';
import { NestedObject } from 'src/types';

import { metricsLogger } from './metricsLogger';

declare function ym(
  id: string,
  type: string,
  name: string,
  prams: NestedObject<string>,
): void;

export const sendYMData = (
  reachGoal: string,
  params: NestedObject<string>,
  withTest?: boolean,
) => {
  let id = testYmId;

  if (process.env.NEXT_PUBLIC_SPACE === 'production' && !withTest) id = ymId;

  metricsLogger(params, 'sendYMData');

  try {
    return ym(id, 'reachGoal', reachGoal, params);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Не удалось загрузить ym', err);
  }
};
