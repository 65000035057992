import { createContext, useContext, useReducer } from 'react';

import { ACTIONS } from './actions';
import { Action, Dispatch, StateType } from './types';

const StoreStateContext = createContext<StateType | undefined>(undefined);
const StoreDispatchContext = createContext<Dispatch | undefined>(undefined);

const storeReducer = (state: StateType, action: Action) => {
  const newState: StateType = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case ACTIONS.UPD_LOADING_STATUS: {
      newState.loadingStatus = action.payload;
      return { ...newState };
    }
    //
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

const initialState: StateType = {
  loadingStatus: 'success',
  platformName: '',
};

interface StoreProviderProps {
  children: React.ReactNode;
}

const StoreProvider = ({ children }: StoreProviderProps) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  return (
    <StoreStateContext.Provider value={state}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  );
};

const useStoreState = () => {
  const context = useContext(StoreStateContext);
  if (context === undefined)
    throw new Error('useStoreState must be used within a StoreProvider');

  return context;
};

const useStoreDispatch = () => {
  const context = useContext(StoreDispatchContext);
  if (context === undefined)
    throw new Error('useStoreDispatch must be used within a StoreProvider');

  return context;
};

export { ACTIONS, StoreProvider, useStoreDispatch, useStoreState };
